h5.tag-list-header {
  font-size: 0.85rem;
}

ul.tag-list {
  font-size: 0.85rem;
  line-height: 1rem;

  flex-flow: column;
  margin: 0 -0.5rem 0.25rem 0;
  gap: 0;

  li {
    gap: 0;
    padding-right: 1rem;
    border: 0;
    width: unset;

    a {
      display: block;
      padding: 0;
      line-height: 1rem;
    }
  
    .tag-list-count {
      padding: unset;
      margin: unset;
      color: themed("color-text-muted");
      font-size: 0.85em;
      border: 0;
    }

    .tag-list-actions { display: flex; }

    &:hover, &.blacklisted {
      padding-right: 0;
      .tag-list-quick-blacklist { display: flex; }
    }
    &.blacklisted {
      .tag-list-quick-blacklist svg {
        color: themed("color-text-muted");
        &:hover { color: themed("color-link-hover"); }
        .line { color: transparent; }
      }
    }
  }
}
