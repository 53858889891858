div#c-maintenance-user-deletions {
  ul {
    margin-left: 1em;
    margin-bottom: 1em;
  }

  li {
    list-style-type: disc;
  }
}
