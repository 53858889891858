.styled-dtext .spoiler {
  background: $spoiler-background;
  // Also hides emojis
  &, a {
    color: transparent;
    text-shadow: 0 0 0 $spoiler-color;
  }

  .inline-code, pre, blockquote, details {
    background-color: $spoiler-background;
  }

  &:hover {
    color: $spoiler-hover-color;

    a {
      // Prevent accidentally opening a link when tapping to reveal it
      animation: delay-pointer-events 50ms;

      @keyframes delay-pointer-events {
        0%, 99% {
          pointer-events: none;
        }
      }

      color: themed('color-spoiler-link');
      &:hover {
        color: themed('color-spoiler-link-hover');
      }
    }

    .inline-code {
      background-color: $dtext-code-background;
    }
  }
}
