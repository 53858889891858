

div#page div#news {
  display: flex;
  flex-flow: column;
  align-items: center;

  padding: 0;
  margin-top: -0.5em;
  border-radius: 0 0 6px 6px;

  max-height: 2rem;
  overflow-y: hidden;
  user-select: none;
  position: relative;
  text-align: unset;

  &.open {
    max-height: none;

    #news-body { pointer-events: unset; }
  }

  #news-header {
    display: block;
    box-sizing: border-box;

    cursor: pointer;
    width: 100%;
    text-align: center;
    padding: 0.5rem;
  }

  #news-body {
    display: block;
    box-sizing: border-box;

    max-width: 800px;
    padding: 0 0.5rem 0.5rem;

    pointer-events: none;
  }

  #news-dismiss {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.25rem 1rem;

    cursor: pointer;
    font-size: 1.25rem;
    font-weight: bold;
  }
}
