#c-bans #a-index {
  tr[data-expired="true"] {
    background-color: $positive-record-background;

    &:hover {
      background-color: darken($positive-record-background, 5%);
    }
  }

  tr[data-expired="false"] {
    background-color: $negative-record-background;

    &:hover {
      background-color: darken($negative-record-background, 5%);
    }
  }
}
