// Legacy helpers to style UI elements using the current theme
.bg-background {
  background-color: themed("color-background");
}

.bg-foreground {
  background-color: themed("color-foreground");
}

.bg-section {
  background-color: themed("color-section");
}

.bg-highlight {
  background-color: themed("color-section-lighten-10");
}

.border-background {
  border: 1px solid themed("color-background");
}

.border-foreground {
  border: 1px solid themed("color-foreground");
}

.border-section {
  border: 1px solid themed("color-section");
}

.border-highlight {
  border: 1px solid themed("color-section-lighten-10");
}

.color-text {
  color: themed("color-text");
}

.color-muted {
  color: themed("color-text-muted");
}

.color-white {
  color: themed("color-text-white");
}

.vpad-05 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.button {
  display: inline-block;
  box-sizing: border-box;
  border-radius: $border-radius-full;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.25rem 0.5rem;
  border: 0;
  color: themed("color-text-white");

  &.btn-danger {
    color: themed("color-text-white");
    background: themed("color-danger");
    &:hover {
      background: themed("color-danger-darken-5");
    }
  }
  &.btn-warn {
    color: themed("color-text-white");
    background: themed("color-warning");
    &:hover {
      background: themed("color-warning-darken-5");
    }
  }
  &.btn-success {
    color: themed("color-text-white");
    background: themed("color-success");
    &:hover {
      background: themed("color-success-darken-5");
    }
  }
  &.btn-neutral {
    color: themed("color-text-white");
    background: themed("color-section");
    &:hover {
      background: themed("color-section-lighten-5");
    }
  }
  &.score-positive {
    color: palette("text-white");
    background: palette("background-green");
    &:hover {
      background: palette("background-green-d5");
    }
  }
  &.score-negative {
    color: palette("text-white");
    background: palette("background-red");
    &:hover {
      background: palette("background-red-d5");
    }
  }
  &.score-neutral {
    color: palette("text-grey");
    background: themed("color-section");
    &:hover {
      background: themed("color-section-darken-5");
    }
  }
}
