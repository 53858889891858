

div.error-messages {
  margin: 1em 0;
  padding: $base-padding;

  h1 {
    font-size: 1em;
    color: $error-notice-color;
  }
}

div#notice {
  padding: 0.25em;
  position: fixed;
  top: 1rem;
  left: 25%;
  width: 50%;
  z-index: 500;

  color: themed("color-text");
  background-color: themed("color-success");
  border: 1px solid themed("color-foreground");

  &.ui-state-error {
    background-color: themed("color-danger");
  }
}

a#close-notice-link {
  display: flex;
  float: right;
  padding: 0 0.75em;
}
