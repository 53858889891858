.dtext-formatter {
  display: grid;
  grid-template-columns: min-content auto;
  background: #00000035;
  border-radius: 4px;
  position: relative;
  
  .dtext-formatter-tabs {
    grid-area: 1 / 1 / 1 / 1;
    white-space: nowrap;
    padding: 0.5rem 0.75rem 0;
    a {
      display: inline-block;
      padding: 0.5rem 0.75rem;
      margin-right: 0.5rem;
      border-radius: 4px 4px 0 0;
      outline: 0;
      font-weight: 500;
      cursor: pointer;
      @include nonselectable;
      &:hover {
        background: #ffffff15;
      }
    }
  }
  
  .dtext-formatter-buttons {
    grid-area: 1 / 2 / 1 / 2;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 0.5rem;
    a {
      box-sizing: border-box;
      padding: 0.5rem 0.625rem;
      border-radius: 6px 6px 0 0;
      margin-right: 0.25rem;
      cursor: pointer;
      line-height: 1;
      @include nonselectable;
      @include font-awesome-icon;
      
      &:hover {
        background: #ffffff15;
      }
    }
    .spacer {
      width: 1em;
    }
  }
  
  .dtext-formatter-input, .dtext-formatter-preview {
    grid-area: 2 / 1 / 2 / 3;
    width: 100% !important;
    max-width: unset !important;
    height: 100%;
    min-height: 8rem;
    box-sizing: border-box;
    border-top: 1px solid #00000035;
    padding: 0.25rem 0.5rem;
    font-family: inherit;
    font-size: 1em;
  }
  
  .dtext-formatter-preview[loading="true"] {
    position: relative;
    &::before {
      content: "Loading . . .";
      font-size: 1.25rem;
      opacity: 0.20;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: loading-pulsate 4s linear infinite;
    }
    @keyframes loading-pulsate {
      from {
        opacity: 0;
      }
      50% {
        opacity: 0.6
      }
      to {
        opacity: 0;
      }
    }
  }
  
  .dtext-formatter-charcount {
    grid-area: 3 / 1 / 3 / 3;
    font-size: 0.75rem;
    line-height: 0.75rem;
    height: 0.75rem;
    position: absolute;
    right: 1.25rem;
    bottom: 0.15rem;
    pointer-events: none;
    &::before {
      content: attr(data-count) " / " attr(data-limit);
    }
    &[data-limit="0"]::before {
      content: attr(data-count);
    }
    &[data-count="0"] {
      visibility: hidden;
    }
    &:not([data-limit="0"]).overfill {
      color: orangered !important;
      font-weight: bold;
    }
  }
  
  // Preview toggle states
  &[data-editing="true"] {
    .dtext-formatter-tabs {
      a[data-action="edit"] {
        pointer-events: none;
        background: #00000040;
      }
    }
    .dtext-formatter-preview {
      display: none !important;
    }
    .dtext-formatter-charcount {
      color: black;
    }
  }
  &[data-editing="false"] {
    .dtext-formatter-tabs {
      a[data-action="show"] {
        pointer-events: none;
        background: #00000040;
      }
    }
    .dtext-formatter-buttons {
      visibility: hidden;
    }
    .dtext-formatter-input {
      display: none !important;
    }
  }
}
