body[data-th-main="hotdog"] {
  // Layout Colors
  --color-background:             #ffff00;                // Main background (dark blue, large hexagons)
  --color-foreground:             #ff0000;                // Foreground (lighter blue, small hexagons)
  --color-section:                #c53c38;                // Foreground sections - boxes, sections, etc
  --color-section-lighten-5:      #{lighten(#c53c38, 5%)};
  --color-section-lighten-10:     #{lighten(#c53c38, 10%)};
  --color-section-darken-5:       #{darken(#c53c38, 5%)};
  --color-table-border:           #ff0000;

  // Main Colors
  --color-text:                   #000000;
  --color-text-muted:             #999999;
  --color-text-table-header:      #{darken(#000000, 10%)};

  --color-link:                   #000000;
  --color-link-hover:             #666666;
  --color-link-active:            #ffe380;
  --color-button-active:          #ffe380;

  --color-active-tag:             #ff0000;

  // DText Colors
  --color-dtext-quote:            #67717b;
  --color-dtext-section:          #427982;

  // User Groups
  --color-user-member:            #000000;
  --color-user-member-alt:        #999999;
  --color-user-privileged:        #000000;
  --color-user-privileged-alt:    #999999;

  --color-user-blocked:           #000000;
  --color-user-blocked-alt:       #999999;

  // Tag Categories
  --color-tag-general:            #000000;
  --color-tag-general-alt:        #999999;

  // Spoilers
  --color-spoiler-link:           #ffff00;
  --color-spoiler-link-hover:     #ff0000;
}
