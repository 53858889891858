div#c-static {
  div#a-site-map {
    display: flex;
    flex-flow: row wrap;
    max-width: 80em;

    section {
      width: 20em;
      ul { margin-bottom: 1.5em; }
    }
  }
}
