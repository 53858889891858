a,
a:link,
a:visited,
.link {
  color: $hexagon-color-link;
  color: themed('color-link');
  text-decoration: none;
}

.link {
  cursor: pointer;
}

a:hover, .link:hover {
  color: themed('color-link-hover');
  text-decoration: none;
}

a:active {
  color: themed('color-link-active');
  text-decoration: none;
}

a.last-page {
  color: themed('color-link-last-page');
}

a.active {
  font-weight: bold;
}

a.wiki-link {
  margin-right: 0.3em;
}
