div#add-to-pool-dialog {
  font-size: 0.8em;

  form {
    margin-bottom: 1em;
  }

  li {
    margin-left: 1em;
    cursor: pointer;
  }

  .hint {
    display: block;
  }
}

div#c-pools {
  div#a-show {
    #blacklist-box {
      display: inline;
    }
    div#description {
      margin-bottom: 1em;
    }
  }
}

div#c-pool-orders {
  div#a-edit {
    ul.ui-sortable {
      list-style-type: none;

      li {
        cursor: pointer;
        margin-right: 20px;
        margin-bottom: 20px;
        min-width: 150px;
        min-height: 150px;
        padding: 10px;
        display: inline-block;
      }

      li.ui-state-default {
        background: none;
      }

      li.ui-state-placeholder {
        background: none;
      }
    }
  }
}
