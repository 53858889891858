

ul.backtrace {
  font-family: monospace;
  font-size: 1.2em;
  list-style-type: none;
  background: themed('color-section');
  padding: 1em;
  margin-bottom: 1em;
}
