#c-tickets #a-new {
  .section {
    max-width: 80em;
  }
  
  form.simple_form {
    background-color: transparent;
  }

  .ticket-warning {
    display: block;
    background: var(--color-section-lighten-5);
    border-radius: 6px;
    padding: 0.5em;
    margin-bottom: 1em;
  
    ul {
      list-style: disc;
      margin: 0.5em 1.2em;
    }
  }
} 

.approved-ticket {
  color: palette("text-green");
  font-weight: bold;
}
.partial-ticket { 
  color: palette("text-yellow");
  font-weight: bold;
}
