$modes: (
  "edit": $post-mode-edit,
  "add-fav": $post-mode-add-fav,
  "remove-fav": $post-mode-remove-fav,
  "add-to-set": $post-mode-add-to-set,
  "remove-from-set": $post-mode-remove-from-set,
  "vote-up": $post-mode-vote-up,
  "vote-down": $post-mode-vote-down,
  "rating-s": $post-mode-rating-s,
  "rating-q": $post-mode-rating-q,
  "rating-e": $post-mode-rating-e,
  "tag-script": $post-mode-tag-script,
  "remove-parent": $post-mode-remove-parent,
  "lock-rating": $post-mode-lock-rating,
  "lock-note": $post-mode-lock-note,
  "approve": $post-mode-approve,
  "delete": $post-mode-delete,
  "undelete": $post-mode-undelete,
  "unflag": $post-mode-unflag,
);

@each $mode, $color in $modes {
  #page[data-mode-menu="#{$mode}"] .content {
    background-color: $color;
  }
}

#tag-script-ui {
  display: flex;
  margin-top: 0.5em;
  gap: 0.5em;

  input { flex: 1; }
  button { padding: 0 0.25em; }
}
