.profile-staff-info {
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 0.25rem 1rem;

  h4 {
    white-space: nowrap;
  }

  .block { grid-column: 1 / -1; }
  h4.block { margin-bottom: -0.25rem; }

  @include window-larger-than(50rem) {
    grid-template-columns: min-content 1fr min-content 1fr;
  }
}
