.guest-warning {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  display: flex;
  justify-content: center;

  background: themed("color-background");

  .guest-warning-dialog {
    margin: 20vh 0.5em 0;

    height: min-content;
    max-width: 360px;

    .dialog-header {
      padding: 0 0.5em;
      margin-bottom: 0.5em;
    }

    .dialog-content {
      background: var(--color-foreground);
      padding: 2em 1em 1em;
      border-radius: 3px 3px 0 0;

      p:last-child { margin-bottom: 0; }
    }

    .dialog-footer {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5em;

      background: var(--color-foreground);
      padding: 1em 1em 2em;
      border-radius: 0 0 3px 3px;

      button {
        background: var(--color-section-lighten-5);
        color: var(--color-link);
        padding: 0.5em 1em;
        border-radius: 3px;

        &:hover {
          background: var(--color-section-lighten-10);
          color: var(--color-link-hover);
        }
      }
    }
  }
}
