

div#c-static {
  div#a-keyboard-shortcuts {
    overflow: visible;

    section {
      min-width: 20%;
      float: left;
      margin-bottom: 1em;
      padding-right: 1em;

      h1 {
        font-size: $h3-size;
      }

      li {
        margin-bottom: 0.5em;
      }
    }
  }
}
