footer.footer-wrapper {
  background: var(--color-foreground);
  margin-top: 1rem;

  .footer-grid {
    display: grid;
    grid-template-columns: min-content min-content;
    grid-template-areas:
      "logo .    "
      "left right";
    justify-items: center;
    gap: 0.5rem 0;

    width: min-content;
    margin: 0 auto;
    padding: 1rem;
  }

  .footer-logo {
    grid-area: logo;
    width: 100%;
    text-align: right;

    img {
      width: 5rem;
      height: auto;

      // Aligning the logo with the line below
      margin-right: -3.25rem;

      background: themed("color-background") themed("image-background");
      border-radius: 25%;
      padding: 0.5rem;
    }
  }

  .footer-left, .footer-right {
    display: flex;
    flex-flow: column;
    box-sizing: border-box;
    width: 100%;
    gap: 0.25rem;
  
    font-size: 1rem;
  
    a, span { white-space: nowrap; }
    span { line-height: 1.25em; }
  }

  .footer-left {
    grid-area: left;
    padding-right: 1rem;

    text-align: right;
    border-right: 1px solid var(--color-section-lighten-5);
  }
  .footer-right {
    grid-area: right;
    padding-left: 1rem;
  }
}


// Desktop-ish
footer.footer-wrapper {
  @include window-larger-than(450px) {
    .footer-grid {
      grid-template-areas: "logo left right";
      padding: 1rem 0;
    }

    .footer-logo {
      display: flex;
      align-items: center;
      padding-right: 2rem;
      box-sizing: border-box;

      img {
        width: 6rem;
        margin-right: unset;
      }
    }
  }
}
