$hex-size: 1.25rem;

.user-record {
  display: flex;

  margin: -0.125rem 0;
  height: 1.25rem;
  width: 1.25rem;

  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.4));

  // Variations
  &.deleted path { fill: palette("background-yellow"); }
  &.negative path { fill: palette("background-red"); }
  &.neutral path { fill: palette("background-grey"); }
  &.positive path { fill: palette("background-green"); }
}

.user-records-list {
  display: flex;
  flex-flow: row;
  gap: 0.25rem;

  &:hover .user-record {
    &.deleted path { fill: palette("background-yellow-d5"); }
    &.negative path { fill: palette("background-red-d5"); }
    &.neutral path { fill: palette("background-grey-d5"); }
    &.positive path { fill: palette("background-green-d5"); }
  }
}
