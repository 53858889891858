div#c-confirm-delete {

  // Raw text input and clear-all button
  #reason { width: 100%; }
  #delreason-clear { width: 5rem; }

  // "Move to parent" checkboxes
  .post_delete_options {
    display: flex;
  }

  // Prebuilt deletion reason buttons
  #delreason-prebuilt {
    a.delreason-button {
      display: block;
      cursor: pointer;
      width: fit-content;
      margin: 0.125rem 0;
      padding: 0;

      // Minus / plus icons
      &::before {
        content: "+";
        margin-right: 0.5em;
        font-family: monospace;
        font-size: 1.25em;
      }

      // Customized inputs
      input[type="text"] {
        background: transparent;
        border: 0;
        background: #ffffff10;
        border-radius: 0.125rem;
        padding: 0 0.25rem;
      }

      // Highlight enabled elements
      &.enabled::before { content: "-"; }

      color: themed("color-link");

      &:hover {
        color: themed("color-link-hover");
        input[type="text"] {
          color: themed("color-link-hover");
          border-color: themed("color-link-hover");
        }
      }

      input[type="text"] {
        border-bottom: 1px solid themed("color-link");
        color: themed("color-link");
        &:focus { color: themed("color-link") !important; }
      }

      &.enabled {
        color: themed("color-link-active");
        input[type="text"] {
          color: themed("color-link-active");
          border-color: themed("color-link-active");
        }
      }
    }
  }

}
