// Thumbnail dimensions
$thumb-image-size: 150px;
$thumb-border-width: 2px;
$thumb-mobile-cols: 3;
$thumb-gap: 8px;


// Switch to desktop view at this width
// Last value is the #page padding
$thumb-desktop-breakpoint: ($thumb-image-size * $thumb-mobile-cols) + (2 * $thumb-gap) + 8px;


// Posts container
// May or may not exist on some pages
section.posts-container {
  display: grid;
  grid-template-columns: repeat($thumb-mobile-cols, 1fr);
  gap: 0.5rem;

  @include window-larger-than($thumb-desktop-breakpoint) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  h2.posts-container-header {
    grid-column: -1 / 1;
  }

  .no-results {
    grid-column: -1 / 1;
  }
}


// Thumbnail proper
article.thumbnail {
  display: inline-flex;
  flex-flow: column;
  max-width: $thumb-image-size;
  justify-self: center; // Center the thumbnail in the grid cell
  position: relative;   // Position badges correctly
  overflow: hidden;

  @include window-larger-than($thumb-desktop-breakpoint) {
    width: min-content; // Force .desc to be the same width as the image
  }

  // Badges
  &[data-tags~=animated] > a:before { @include preview-type-badge("ANIM"); }
  &[data-file-ext=webm] > a:before { @include preview-type-badge("WEBM"); }

  a { text-align: center; } // Fixes an issue with blacklisted pool thumbnails

  img {
    box-sizing: border-box; // Prevent the border from resizing the entire element
    max-width: $thumb-image-size;
    max-height: $thumb-image-size;

    border-radius: $border-radius-half $border-radius-half 0 0;
    pointer-events: none;   // Should probably just fix the click events, huh

    // Mobile view
    width: 100%;
    @include window-larger-than($thumb-desktop-breakpoint) {
      width: unset;
    }
  }

  .desc {
    display: flex;
    gap: 0.25em;
    justify-content: center;

    background-color: themed("color-section-lighten-5");
    border-radius: 0 0 $border-radius-half $border-radius-half;

    .rating { font-weight: 700; }
    & > a { text-align: center; } // Pool names
  }

  // Color the rating letters
  @each $name, $color in ("e": "red", "q": "yellow", "s": "green") {
    &[data-rating=#{$name}] .desc .rating { color: palette("text-#{$color}"); }
  }

  // Post matches blacklist
  &.filter-matches .desc {
    background-color: var(--palette-background-red);
  }
}


// Fix for when posts have no cropped versions
// Should be made irrelevant later, but this will do for now
@include window-smaller-than(800px) {
  body[data-disable-cropped-thumbnails="false"] article.thumbnail {
    width: 100%;
    a { height: 100%; }
    img {
      height: 100%;
      object-fit: cover;
      @include window-larger-than($thumb-desktop-breakpoint) {
        &.has-cropped-false { width: 150px; }
      }
    }
  }
}


// I have absolutely no idea what this
// does or when it might be triggered.
article.thumbnail.current-post {
  background-color: $post-preview-highlight-background;
}


// Nightmarish mess ahead
// Set thumbnail borders based on status
article.thumbnail {
  &.has-children img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color;
  }

  &.has-parent img {
    border: 2px solid transparent;
    border-color: $preview-has-parent-color;
  }

  &.has-children.has-parent img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color $preview-has-parent-color $preview-has-parent-color $preview-has-children-color;
  }


  &.deleted img {
    border: 2px solid transparent;
    border-color: $preview-deleted-color;
  }

  &.has-children.deleted img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color $preview-deleted-color $preview-deleted-color $preview-has-children-color;
  }

  &.has-parent.deleted img {
    border: 2px solid transparent;
    border-color: $preview-has-parent-color $preview-deleted-color $preview-deleted-color $preview-has-parent-color;
  }

  &.has-children.has-parent.deleted img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color $preview-deleted-color $preview-deleted-color $preview-has-parent-color;
  }


  &.pending img,
  &.flagged img {
    border: 2px solid transparent;
    border-color: $preview-pending-color;
  }

  &.has-children.pending img,
  &.has-children.flagged img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color $preview-pending-color $preview-pending-color $preview-has-children-color;
  }

  &.has-parent.pending img,
  &.has-parent.flagged img {
    border: 2px solid transparent;
    border-color: $preview-has-parent-color $preview-pending-color $preview-pending-color $preview-has-parent-color;
  }

  &.has-children.has-parent.pending img,
  &.has-children.has-parent.flagged img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color $preview-pending-color $preview-pending-color $preview-has-parent-color;
  }
}

// Flagged posts have red borders for approvers.
body[data-user-can-approve-posts="true"] article.thumbnail {
  &.flagged img {
    border: 2px solid transparent;
    border-color: $preview-flagged-color;
  }

  &.has-children.flagged img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color $preview-flagged-color $preview-flagged-color $preview-has-children-color;
  }

  &.has-parent.flagged img {
    border: 2px solid transparent;
    border-color: $preview-has-parent-color $preview-flagged-color $preview-flagged-color $preview-has-parent-color;
  }

  &.has-children.has-parent.flagged img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color $preview-flagged-color $preview-flagged-color $preview-has-parent-color;
  }
}
