body[data-th-main="serpent"] {
  // Layout Colors
  --color-background:             #44a544;                // Main background (dark blue, large hexagons)
  --color-foreground:             #dacf6b;                // Foreground (lighter blue, small hexagons)
  --color-section:                #fff48b;                // Foreground sections - boxes, sections, etc
  --color-section-lighten-5:      #{lighten(#fff48b, 5%)};
  --color-section-lighten-10:     #{lighten(#fff48b, 10%)};
  --color-section-darken-5:       #{darken(#fff48b, 5%)};
  --color-table-border:           #dacf6b;

  // Main Colors
  --color-text:                   #000000;
  --color-text-muted:             #555555;
  --color-text-table-header:      #{darken(#000000, 10%)};

  --color-link:                   #005500;
  --color-link-hover:             #3A8F3A;
  --color-link-active:            #e8c446;
  --color-button-active:          #e8c446;

  --color-active-tag:             #44a544;

  // DText Colors
  --color-dtext-quote:            #67717b;
  --color-dtext-section:          #427982;

  // User Groups
  --color-user-member:            #005500;
  --color-user-member-alt:        #3A8F3A;
  --color-user-privileged:        #005500;
  --color-user-privileged-alt:    #3A8F3A;

  --color-user-blocked:           #005500;
  --color-user-blocked-alt:       #3A8F3A;

  // Tag Categories
  --color-tag-general:            #005500;
  --color-tag-general-alt:        #3A8F3A;

  // Spoilers
  --color-spoiler-link:           #44a544;
  --color-spoiler-link-hover:     #e8c446;
}
