

@each $tag-category, $tag-category-name in $tag-categories {
  .category-#{$tag-category} a,
  a.tag-type-#{$tag-category},
  .ui-widget-content .category-#{$tag-category} a,
  .ui-widget-content a.tag-type-#{$tag-category} {

    &, &:link, &:visited {
      color: themed("color-tag-#{$tag-category-name}");
    }

    &:hover {
      color: themed("color-tag-#{$tag-category-name}-alt");
    }

    &.selected {
      color: $tag-selected-color;
    }
  }

  .ui-widget-content .ui-state-active a.tag-type-#{$tag-category} {
    color: themed("color-tag-#{$tag-category-name}-alt");

    &:link, &:visited {
      color: themed("color-tag-#{$tag-category-name}");
    }

    &:hover {
      color: themed("color-tag-#{$tag-category-name}-alt");
    }

    &.selected {
      color: $tag-selected-color;
    }
  }
}

ul.inline-tag-list {
  display: inline;

  li {
    display: inline;
    margin-right: 0.5em;
  }
}

.post-count {
  font-size: 85%;
  font-weight: 500;
  margin-left: 0.2em;
}

.low-post-count {
  color: $post-tag-low-count-color;
}
