// Fullscreen mode
// Only relevant on desktop
.post-index {
  grid-template-areas:
    "search " 
    "content";
  grid-template-columns: 1fr;

  .search {
    display: flex;
    border-radius: 0.25rem 0.25rem 0 0;
    box-shadow: inset 0px -0.25rem 0.25rem -0.25rem themed("color-background");

    margin: 0.25rem 0 0;
    padding: 0.5rem;

    flex-wrap: wrap;
    justify-content: right;
    z-index: 11; // above posts and labels

    .post-search { width: 100%; }

    #search-fullscreen {
      background: themed("color-button-active");
      color: black;
    }

    .search-controls { right: 0.25rem; }
  }
  .sidebar { display: none; }
  .content {
    border-radius: 0 0 0.25rem 0.25rem;
    .posts-index-stats { margin-right: 5rem; }
  }
}

#c-posts #a-show .post-index {
  .search { padding-bottom: 1.25rem; }
  .search-controls { bottom: -0.883rem; }
  .content { padding-top: 1rem; }
}
