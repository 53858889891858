

#ad-leaderboard {
  margin-bottom: $padding-050;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;

  .adzone {
    height: 90px;
    width: 768px;
  }
}

