#c-edit-history {
  .edit-item {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 1em;
  }

  .original {
    white-space: pre-wrap;
  }
}
