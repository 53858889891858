.post-index .search {
  position: sticky;
  top: 0;

  background: themed("color-foreground");
  border-radius: 0.25rem;

  // on top of thumbnail labels
  z-index: 11;

  #search-sticky {
    background: themed("color-button-active");
    color: black;
  }
}

&[data-th-sheader="true"] {
  .post-index .search { top: 3.75rem; }
}
