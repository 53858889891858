

span.error {
  display: block;
  font-weight: bold;
  color: red;
}

span.count {
  color: themed("color-text-muted");
  margin-left: 0.5em;
}

kbd.key {
  background: $keyboard-key-background;
  padding: 2px 7px;
  color: $keyboard-key-color;
/*  width: 1em;*/
  text-align: center;
  display: inline-block;
  border-radius: $border-radius-half;
}

span.inactive {
  color: themed("color-text-muted");
}
