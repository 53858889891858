body.c-posts.a-show {
  #page {
    // Override the theme to instead
    // project it upon the content area
    background: themed("color-foreground");
    padding: 0;
  }

  #page, #c-posts, #a-show {
    display: flex;
    flex-flow: column;
    flex: 1;
  }
}

// Due to the show-seq page having a different action,
// using the body classes is not possible here
#c-posts, #a-show {
  @import "partials/post_information";
}
