body.c-maintenance-user-passwords.a-edit {

  input[type="password"] {
    width: 100%;
    max-width: unset;
    box-sizing: border-box;
  
    @include st-radius;
    @include st-padding;
    font-size: 1rem;
  
    @include window-larger-than(50rem) {
      font-size: 0.85rem;
    }
  }

  input[type="submit"], .cancel-btn {
    font-size: 1.25rem;
    padding: 0.25rem 1rem;
    border-radius: 0.25rem;
    vertical-align: middle;

    background: themed("color-section-lighten-5");
    &:hover, &:active { background: themed("color-section-lighten-10"); }
  }

  input[type="submit"] {
    background: themed("color-tag-artist");
    &:hover, &:active { background: themed("color-tag-artist-alt"); }
  }

  form.simple_form {
    background: unset;
    padding: unset;
    margin: unset;
    max-width: unset;
  }

  .settings-container {
    max-width: 50rem;
    margin-top: 0.5rem;

    &-header {
      background: themed("color-section-lighten-5");
      @include st-radius-top;

      font-size: 1rem;
      line-height: 1rem;
      padding: 0.5rem;
    }

    &-content {
      background: themed("color-section");
      @include st-radius-bottom;

      padding: 0.5rem;

      display: grid;
      grid-template-columns: 1fr;
      gap: 0.5rem;

      @include window-larger-than(50rem) {
        grid-template-columns: 1fr 360px;
      }

      .settings-input { max-width: 360px; }
      .settings-confirm {
        grid-column: 1 / -1;
        text-align: right;
      }
    }
  }
}
