$fa-font-path: "@fortawesome/fontawesome-free/webfonts"; 

@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/regular";

// https://fontawesome.com/how-to-use/on-the-web/advanced/css-pseudo-elements
@mixin font-awesome-icon {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}
