button, input, select, textarea {
  border: 0;
  border-radius: 2px;
  padding: 1px 2px;
}

label {
  font-weight: bold;
  white-space: nowrap;
}

textarea {
  display: block;
  font-family: monospace;
  font-size: 1.2em;
}

textarea.no-block {
  display: inline;
}

input[type=button] {
  min-width: 5rem;
}

input[type=submit] {
  min-width: 5rem;
}

input[type=radio] {
  border-radius: 0;
  box-shadow: none;
}

input:focus {
  background: $form-focus-background;
  color: $form-focus-color;
}

select:focus {
  background: $form-focus-background;
  color: $form-focus-color;
}

textarea:focus {
  background: $form-focus-background;
  color: $form-focus-color;
}

input:disabled {
  background-color: $form-disabled-background;
}

.simple_form {
  padding: $padding-050;
  border-radius: $border-radius-half;
  background-color: themed("color-section");
}

// Password validation
.password-input {
  input[type="password"] {
    border-radius: radius() radius() 0 0 !important;
    padding-bottom: 0 !important;
  }

  .password-strength {
    width: 100%;
    height: 0.25rem;
    border-radius: 0 0 radius() radius() !important;

    background: white;
    overflow: hidden;
    margin: 0;

    .password-progress {
      background: linear-gradient(to right, palette("text-red") 0%, palette("text-yellow") 25%, palette("text-green") 100%);
      background-size: 360px 100%;

      height: 100%;
      transition: width 1s ease-in-out;
    }
  }

  .password-feedback {
    display: flex;
    flex-flow: column;
    padding-left: 1em;

    span { display: list-item; }
    .password-warning { font-weight: bold; }
    &:not([count="0"]) {
      margin-top: 0.5em;
    }
  }
}
