

div#c-tags {
  div#a-index table.striped {
    td:nth-child(1), th:nth-child(1) {
      width: 5em;
      text-align: right;
    }

    td:nth-child(2), th:nth-child(2) {
      padding-left: 1em;
      width: 40em;
    }
  }
}

#tags-container {
  max-width: 70em;

  div.header {
    margin: 0;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;

    label {
      grid-column: 1;
    }

    .options {
      grid-column: 2;
      justify-self: end;

      .count {
        text-decoration: italic;
        margin-left: 0.25em;
        padding-bottom: 0.2em;
      }

      i {
        margin-left: 0.25em;
        font-size: 11pt;
      }

      .fa-face-frown {
        color: $tags-vote-down-color;
      }

      .fa-face-meh {
        color: $tags-vote-meh-color;
      }

      .fa-face-smile {
        color: $tags-vote-up-color;
      }
    }
  }
}
