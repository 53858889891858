div#c-popular {
  header {
    text-align: center;

    h1 {
      font-size: 3em;
    }

    p.byline {
      font-size: 1.2em;
      font-style: italic;
    }
  }

  div.posts {
    padding: 1em;
    margin-bottom: 2em;
  }
}
