

div#note-container {
  position: absolute;
  z-index: 50;

  &[data-resizing=true] {
    display: none;
  }

  div.note-body {
    position: absolute;
    border: 1px solid $note-border;
    background: $note-background;
    color: $note-color;
    min-width: 140px;
    min-height: 1em;
    line-height: normal;
    cursor: pointer;
    padding: 4px;
    z-index: 150;
    overflow: auto;
  }

  div.note-box {
    position: absolute;
    border: 1px solid $note-box-border;
    min-width: 5px;
    min-height: 5px;
    width: 100px;
    height: 100px;
    cursor: move;
    background: $note-box-background;
    line-height: normal;

    div.note-box-inner-border {
      border: 1px solid $note-box-inner-border;
      background: $note-box-inner-background;
    }

    div.note-box-inner-border.unsaved {
      border: 1px solid $note-box-inner-unsaved-border;
    }

    &.note-box-highlighted {
      outline: 2px solid $note-box-highlighted-outline;
    }
  }
}

div#note-preview {
  position: absolute;
  border: 1px solid red;
  opacity: 0.6;
  display: none;
  background: $note-preview-background;
}

div.note-edit-dialog {
  font-size: 0.8em;
}
