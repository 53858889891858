h5.tag-list-header {
  font-size: 1.25rem;
  cursor: pointer;

  &.hidden-category::before {
    content: "► ";
  }
}

ul.tag-list {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  gap: 0.5rem 0.25rem;
  margin: 0.25rem 0;

  font-size: 1.1rem;

  li {
    display: flex;
    width: fit-content;
    border: 0.125rem solid themed("color-section-lighten-5");
    border-left-width: 0.25rem;
    @include st-radius;

    a {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      padding: 0.125rem 0.5rem 0.125rem 0.5rem;
    }

    @each $tag-category, $tag-category-name in $tag-categories {
      &.tag-#{$tag-category-name} {
        border-left-color: themed("color-tag-#{$tag-category-name}");
        &:hover { border-left-color: themed("color-tag-#{$tag-category-name}-alt"); }

        a, a:link { color: themed("color-tag-#{$tag-category-name}"); }
        a:hover, a:active { color: themed("color-tag-#{$tag-category-name}-alt"); }
      }
    }
  }

  // 1 wiki: desktop
  .tag-list-wiki {
    display: flex;
    align-self: flex-start;
    padding-right: 0.5rem;
  }

  // 1.5 inc: desktop
  .tag-list-inc {
    display: flex;
    align-self: flex-start;
    padding-right: 0.25rem;
  }

  // 2 tag: both
  .tag-list-search {
    
    svg {
      color: palette("color-tag-artist");
      .check { color: themed("color-text"); }
    }
  }

  // 3 count: both
  .tag-list-count {
    font-size: 0.75em;
    border-left: 1px solid themed("color-section");
    padding-left: 0.5rem;
  }

  // 4 actions: desktop
  .tag-list-actions {
    display: none; // flex
    align-self: flex-start;

    margin-left: auto;
    padding-left: 0.5rem;

    button {
      display: none; // flex
      background: unset;
      padding: 0;

      vertical-align: middle;
      color: themed("color-link");
      &:hover, &:active { color: themed("color-link-hover"); }

      svg {
        height: 1rem;
        width: 1rem;
      }
    }
  }
}
