body[data-th-main="pony"] {
  // Layout Colors
  --color-background:             #250D51;                // Main background (dark blue, large hexagons)
  --color-foreground:             #2f175c;                // Foreground (lighter blue, small hexagons)
  --color-section:                #453269;                // Foreground sections - boxes, sections, etc
  --color-section-lighten-5:      #{lighten(#453269, 5%)};
  --color-section-lighten-10:     #{lighten(#453269, 10%)};
  --color-section-darken-5:       #{darken(#453269, 5%)};
  --color-table-border:           #2f175c;

  // Main Colors
  --color-text:                   #ffffff;
  --color-text-muted:             #999999;
  --color-text-table-header:      #{darken(#ffffff, 10%)};

  --color-link:                   #b4c7d9;
  --color-link-hover:             #e9f2fa;
  --color-link-active:            #e8c446;
  --color-button-active:          #e8c446;

  --color-active-tag:             #6f36da;

  // DText Colors
  --color-dtext-quote:            #67717b;
  --color-dtext-section:          #427982;

  // User Groups
  --color-user-member:            #e5d6ff;
  --color-user-member-alt:        #2e76b4;
  --color-user-privileged:        #e5d6ff;
  --color-user-privileged-alt:    #2e76b4;

  --color-user-blocked:           #e5d6ff;
  --color-user-blocked-alt:       #2e76b4;

  // Tag Categories
  --color-tag-general:            #e5d6ff;
  --color-tag-general-alt:        #2e76b4;
}
