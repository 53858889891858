.ui-widget {
  font-family: $base_font_family;

  input, select, textarea, button {
    font-family: $base_font_family;
  }
}

div.ui-dialog {
  height: auto;
  overflow: visible;
  font-size: 1em;
  z-index: 999;
  box-shadow: none;
  padding: 0;

  .ui-dialog-titlebar {
    border-radius: 0;
    border: none;
  }

  ul {
    margin-left: 1em;
    margin-bottom: 1em;
  }
}

.ui-state-highlight {
  background-color: themed('color-section');
  color: themed('color-text');
  border: 1px solid var(--color-background);

  a, a:link, a:active, a:visited {
    color: themed('color-link');
  }

  a:hover {
    color: themed('color-link-hover');
  }
}

.ui-state-error {
  border: 1px solid $widget-error-border;
  background: $widget-error-background;
}

.ui-button {
  margin: 0;
  padding: 0.25em 1em;
  color: $widget-button-color;
  border: 1px solid $widget-button-border;
  background-color: $widget-button-background;

  &.sub {
    font-size: 90%;
  }

  &.tiny {
    font-size: 90%;
    padding: 0.25em 0.5em;
  }

  &:hover {
    box-shadow: 1px 1px $widget-button-shadow;
    background: $widget-button-hover-background;
  }
}

.ui-front {
  z-index: 1000;
}

.ui-widget-content {
  border: 1px solid themed("color-text-muted");
  background-color: themed("color-section-lighten-10");
  color: themed("color-text");

  a {
    color: themed("color-link");

    &:hover {
      color: themed("color-link-hover");
    }
  }
}

.ui-widget-header {
  background-color: themed("color-background");
  color: themed("color-text");
}
