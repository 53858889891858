

div#c-takedowns {
  .takedown-vericode {
    border-radius: $border-radius-half;
    padding: $base-padding;
    font-weight: bold;
    cursor: default;
  }

  .takedown-instructions-header {
    font-size: 16pt;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #takedown-posts {
    overflow: hidden;
  }

  #takedown-add-posts-tags-warning {
    display: none;
  }

  #takedown-add-posts-tags-confirm, #takedown-add-posts-tags-cancel {
    display: none;
    background-color: #FFEB7E;
  }

  #takedown-post-buttons {
    margin-bottom: 20px;
  }

  .takedown-post-label {
    display: inline-block;
    height: 20px;
    width: 80px;
    vertical-align: middle;
    line-height: 20px;
    margin-bottom: 2px;
    margin-right: 5px;
    cursor: pointer;
  }

  .takedown-postall-label {
    text-align: center;
  }

  .takedown-post-label span {
    position: relative;
    top: -2px;
  }

  .takedown-post-remove {
    width: 20px;
    background-color: $state-error-color;
    text-align: center;
  }

  .takedown-post-keep {
    background-color: $state-success-color;
  }

  .takedown-post-delete {
    background-color: $state-error-color;
  }
}

div#c-static div#a-takedown {
  ul {
    list-style: disc;
    padding: revert;
  }
}
